@font-face {
    font-family: 'ApfelGrotezk';
    src: url('./../../fonts/ApfelGrotezk-Regular.ttf');
}

@font-face {
    font-family: 'WorkSans';
    src: url('./../../fonts/WorkSans-Light.ttf');
}
.page-content {
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        margin-bottom: $line-height-computed;
        font-family: 'WorkSans', sans-serif;
        font-weight: normal;
    }

    h1, .h1, h2, .h2 {
        margin-top: 0;
    }

    h2, .h2 {
        span {
            color: $gray-light;
            font-size: $font-size-large;
            padding-left: $padding-xs-horizontal;
        }
    }
}

.dl-horizontal {
    @extend .row;

    dt {
        @extend .col-sm-4;
        text-align: left;
        color: $brand-primary;
    }

    dd {
        @extend .col-sm-8;
        margin-left: 0;
    }
}
