.ms-container {
    @extend .clearfix;

    background: transparent url('../img/multiselect-switch.png') 50% 50% no-repeat;

    .ms-selectable, .ms-selection {
        width: 45%;
        float: left;
        background: #fff;
        color: #000;
    }

    .ms-selection {
        float: right;
    }

    .ms-list {
        @extend .form-control;
        @extend .clearfix;

        height: $input-height-base * 10;
        overflow-y: auto;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .ms-optgroup-container {
        width: 100%;
        margin-bottom: $padding-base-vertical;
    }

    .ms-optgroup-label {
        color: $gray-light;
        font-weight: bold;
        padding: $padding-small-vertical $padding-small-horizontal;
        border-bottom: 1px $gray-light solid;
        margin-bottom: $padding-base-vertical;
    }

    .ms-selectable li.ms-elem-selectable, .ms-selection li.ms-elem-selection {
        padding: $padding-small-vertical $padding-small-horizontal;
        border-bottom: 1px $gray-lighter solid;
    }

    .ms-selectable, .ms-selection {
        li.ms-hover {
            cursor: pointer;
            color: $btn-primary-color;
            background-color: $btn-primary-bg;
        }

        li.disabled {
            cursor: no-drop;
            color: $btn-default-color;
            background-color: $btn-default-bg;
        }
    }

    .ms-selectable-header, .ms-selection-header {
        font-weight: bold;
        padding: $padding-small-vertical 0;
    }

    .ms-selectable-search, .ms-selection-search {
        input {
            padding: $padding-small-vertical $padding-small-horizontal;
            height: $input-height-base + ($padding-small-vertical * 2);
            border-bottom: none;
        }
    }
}
