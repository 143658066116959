form {
    label {
        color: $brand-primary;
    }

    .form-group {
        &:after {
            display: block !important;
            margin-left: ceil(($grid-gutter-width / 2));
            padding-bottom: $form-group-margin-bottom;
            border-bottom: 1px dotted $gray-light;
        }
    }

    > .form-group {
        &:last-of-type:after {
            border-bottom: none;
            padding-bottom: 0;
        }
    }

    .disabled {
       opacity: 0.4;
   }
}

option[disabled] {
    opacity: 0.4;
}

fieldset {
    margin-bottom: $line-height-computed * 2;

    &:last-child {
        margin-bottom: 0;

        > .form-group:last-child:after {
            border-bottom: none;
            padding-bottom: 0;
        }

        &.tasks-article-selector {
            > .form-group:last-child:after {
                border-bottom: 1px dotted $gray-light;
                padding-bottom: $form-group-margin-bottom;
            }
        }
    }
}

.radio, .checkbox {
    label {
        font-weight: bold;
    }
}

.form-horizontal {
    .control-label {
        padding-top: 0;
        text-align: left;
    }

    .radio, .checkbox, .radio-inline, .checkbox-inline {
        padding-top: 0;
    }

    .radio, .checkbox {
        min-height: $input-height-base;
    }
}

.form-scanner {
    .form-control{
        font-size: $font-size-base*5;
        height: 1.2em;
    }
}

.form-control {
    padding: $input-padding-vertical $input-padding-horizontal;

    &.currency, &.number {
        width: 50px;
    }
    &.long-number {
        width: 75px;
    }
    td.right & {
        text-align: right;
    }
    td & {
        display: inline;
    }
    td &[type=checkbox] {
        width: auto;
        display: block;
    }
}

input[type="radio"], input[type="checkbox"] {
    margin-top: 1px;
}

.help-block {
    margin-bottom: 0;
}

legend {
    font-size: $font-size-h3;
    font-weight: bold;
    border-bottom: none;
}

.filter-form {
    margin-bottom: $navbar-margin-bottom;
}

// input groups and input addons
.input-group-addon {
    padding: 0 $padding-xs-horizontal;
    color: $btn-default-color;
}

button.input-group-addon {
    padding-top: 2px;
    padding-bottom: 2px;
}

.input-group {
    &.language {
        width: 100%;

        .input-group-addon {
            width: 110px;
            text-align: left;
            background: $gray-lighter;
            color: $text-color;
            border: 1px solid $input-border;
            border-right: none;
            vertical-align: top;
            padding-right: $padding-large-horizontal;
            padding-top: $input-padding-vertical + 1px;

            &:after {
                content: ": ";
                display: inline;
            }
        }
    }
}

form.form-horizontal {
    .input-group + .input-group {
        margin-top: $padding-base-vertical;
    }
}

form.similarities-form {
    .form-control {
        width: 200px;
    }

    table {
        width: auto;
    }
}

.form-control-static {
    padding-top: 0;
    padding-bottom: 0;
    min-height: $input-height-base;

    .form-control + & {
        padding-top: ($padding-base-vertical + 1);
    }
}
