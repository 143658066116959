.btn {
    font-weight: bold;

    .fa {
        font-size: $fa-font-size-base;
    }
}

.actions .btn-group {
    display: flex;
}

.btn-group + .btn-group, .btn-toolbar + .btn-toolbar {
    margin-top: $navbar-margin-bottom;
}
