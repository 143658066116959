.page-statusbar {
    height: $page-statusbar-height;
}

.ready {
    color: $dashboard-color-ready;
}

.waiting {
    color: $dashboard-color-waiting;
}

.error, .overdue {
    color: $dashboard-color-overdue;
}

.disabled {
    opacity: 0.4;
}

.dashboard-legend {
    position: fixed;
    left: $grid-gutter-width;
    bottom: 50px;

    .badge {
        color: $text-color;
        background-color: transparent;

        &.badge-ready {
            background-color: $dashboard-color-ready;
        }

        &.badge-waiting {
            background-color: $dashboard-color-waiting;
        }

        &.badge-overdue {
            background-color: $dashboard-color-overdue;
        }
    }
}
