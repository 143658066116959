.pagination {
    > li {
        > a, > span {
            border: none;
            background-color: transparent !important;
            border-right: 1px solid $navbar-default-link-hover-color;
            padding: 0 $padding-small-horizontal;
            line-height: 1;
        }

        > span {
            color: $pagination-disabled-color;
        }

        &:last-child > a, &:last-child > span {
            border-right: none;
        }
    }
}
