/*
 * Bootstrap 3 variables
 */

// colors
$brand-primary: #000;
$brand-secondary-green: #638183;
$brand-secondary-yellow: #5f5315;
$brand-secondary-ogre: #836f50;
$brand-secondary-blue: #00264e;
$brand-secondary-red: #74222a;
$brand-secondary: $brand-secondary-green;

$gray-light: #919191;
$gray-lighter: #f1f1f1;


// typography
// $font-family-base: ApfelGrotezk, "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-size-base: 12px;

$headings-color: $brand-primary;
$headings-font-weight: bold;

$font-size-h2: 23px;
$font-size-h3: 17px;

// components
$border-radius-base: 0;
$border-radius-large: 0;
$border-radius-small: 0;

// buttons
$btn-default-color: #fff;
$btn-default-bg: #d2d3d4;

// forms
$input-height-base: 20px;
$input-height-large: ceil($input-height-base * 1.5);
$input-height-small: ceil($input-height-base / 1.3);

$form-group-margin-bottom: 12px;
$input-group-addon-bg: $brand-primary;
$input-group-addon-border-color: $input-group-addon-bg;

$input-padding-vertical: ($input-height-base / 10);
$input-padding-horizontal: ($input-height-base / 10);

// tables
$table-bg-accent: $gray-lighter;
$table-bg-hover: #aaa;

// grid system
$grid-gutter-width: 23px;

// container sizes
$container-tablet:             100%;
$container-sm:                 100%;
$container-desktop:            100%;
$container-md:                 100%;
$container-lg:                 100%;

// navbar
$navbar-height: 25px;
$navbar-default-bg: $brand-primary;
$navbar-default-link-color: #fff;
$navbar-default-link-hover-color: $brand-secondary;
$navbar-default-link-active-color: $brand-secondary;
$navbar-default-link-active-bg: transparent;

// navs
$nav-link-padding: 0 10px 0 0;

// pagination
$pagination-border: #000;
$pagination-hover-border: $pagination-border;
$pagination-active-border: $pagination-border;
$pagination-disabled-border: $pagination-border;
$pagination-active-color: #000;
$pagination-hover-color: #000;
$pagination-disabled-color: #000;

// breadcrumbs
$breadcrumb-bg: transparent;
$breadcrumb-padding-vertical: 0;
$breadcrumb-padding-horizontal: 0;


/*
 * custom variables
 */

// page footer
$page-footer-margin: 25px 0 0 0;

// sizes
$page-header-height: 85px;
$page-statusbar-height: 60px;

// dashboard colors
$dashboard-color-ready: green;
$dashboard-color-waiting: orange;
$dashboard-color-overdue: red;
