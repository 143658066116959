.ajax-progress {
    &, * {
        cursor: progress;
    }
}

.ajax-busy {
    &, * {
        cursor: wait;
    }
}
