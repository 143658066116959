.twitter-typeahead {
    width: 100%;
}

.tt-menu {
    width: 100%;
    background: $input-bg;
    border: 1px solid $input-border;

    .tt-suggestion {
        padding: $input-padding-vertical $input-padding-horizontal;
        border-bottom: 1px solid $input-border;
        cursor: pointer;

        small {
            color: $gray-light;
            font-size: $font-size-small;
            margin-left: $padding-base-horizontal;
        }

        &.tt-cursor, &:hover {
            color: $btn-primary-color;
            background-color: $btn-primary-bg;
        }

        &:last-child {
            border-bottom: none;
        }
    }
}
