.page-footer {
    border-top: 1px dotted $brand-primary;
    padding: $panel-footer-padding;
    margin: $page-footer-margin;
    @include clearfix;

    p {
        text-align: center;
        color: $text-muted;
        font-size: $font-size-small;
    }
}
