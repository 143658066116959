.alert {
    > .fa {
        width: 12px;
        text-align: center;
    }

    address {
        margin-top: $line-height-computed;
        margin-bottom: 0;
    }
}
