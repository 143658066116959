.navbar-default {
    .navbar-nav.pull-right {
        > li > a {
            color: $navbar-default-link-color;

            &:hover,
            &:focus {
                color: $navbar-default-link-active-color;
            }
        }

        > .active > a {
            &,
            &:hover,
            &:focus {
                color: $navbar-default-link-active-color;
            }
        }
    }
}

.navbar {
    border-radius: 0;

    .navbar-nav.pull-left {
        margin-left: $grid-gutter-width;
    }
}

.navbar-top {
    @extend .navbar-default;

    .navbar-nav {
        > li {
            &:nth-child(1n+2):before {
                display: inline-block;
                content: "|";
                padding: $nav-link-padding;
                color: $navbar-default-link-color;
            }

            > a {
                display: inline-block;
            }
        }

        &.pull-right {
            > li:nth-child(1n+2):before {
                color: invert($navbar-default-link-color);
            }
        }
    }
}

.navbar-sidebar {
    @extend .navbar-default;
    background-color: transparent;
    border: none;

    .navbar-nav {
        li a {
            color: $gray-light;
            text-decoration: none;

            &:hover,
            &:focus {
                color: $navbar-default-link-hover-color;
            }
        }

        .active a {
            &,
            &:hover,
            &:focus {
                color: $navbar-default-link-hover-color;
            }
        }

        ul {
            list-style: disc;
        }

        > li {
            width: 100%;
        }
    }
}

.navbar-nav > li > a {
    padding-bottom: 0;
}
