.dashboard {
    section {
        margin-bottom: ($line-height-computed * 2);
    }

    .table {
        form {
            input, select {
                width: auto !important;
            }
        }
    }

    .remit-text {
        .input-group {
            width: 70%;

            input {
                width: inherit !important;
            }
        }
    }
}
