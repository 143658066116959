.page-content {
    .table {
        th, td {
            padding: $padding-xs-vertical $padding-xs-horizontal;
        }

        td.actions {
            i.fa {
                font-size: $fa-font-size-base;
            }
        }
    }
}

.table-horizontal {
    > thead > tr > th, > tbody > tr > th, > tfoot > tr > th {
        color: $brand-primary;
        padding-left: 0 !important;
    }

    > thead > tr > th, > head > tr > td,
    > tbody > tr > th, > tbody > tr > td,
    > tfoot > tr > th, > tfoot > tr > td {
        border-top: none;
    }
}

/* das hier gehört zu bootstrap $table-bg-hover: #aaa, welches in _variables.scss überschieben wird */
.table-hover {
    > tbody > tr:hover {
        color: white;
    }
    > tbody > tr:hover input[type=text] {
        color: black;
    }
}

.table {
    th, td {
        &.actions {
            .btn-group {
                float: right;
            }
        }

        &.right {
            text-align: right;
        }
    }

    tr {
        &.indented-row {
            > th:not(.actions), > td:not(.actions) {
                padding-left: $padding-xs-horizontal * 3;
            }
        }

        &.focus td {
            color: white;
            background-color: #aaaaaa;
        }
    }

    &.table-striped-column {
        > thead > tr > th:nth-of-type(even),
        > tbody > tr > td:nth-of-type(odd) {
            background-color: $table-bg-accent;
        }
    }

    &.table-striped-body {
        > tbody:nth-of-type(odd) {
          background-color: $table-bg-accent;
        }
    }

    tr[data-toggle-target] {
        cursor: pointer;
    }

    th.upright {
        position: relative;
        top: 0;
        left: 0;
        min-width: 2em;
    }

    th.upright div {
        position: absolute;
        -moz-transform: rotate(-90deg);
        -moz-transform-origin: -2px 2px;
        -webkit-transform: rotate(-90deg);
        -webkit-transform-origin: -2px 2px;
        -o-transform: rotate(-90deg);
        -o-transform-origin: -2px 2px;
        -ms-transform: rotate(-90deg);
        -ms-transform-origin: -2px 2px;
        transform: rotate(-90deg);
        transform-origin: -2px 2px;
        padding-left: 5px;
    }
}

form.shipment table {
    label {
        &.radio-inline {
            width: 100px;
        }
    }

    .list-inline {
        padding-top: 4px;
        margin-left: 100px;
        padding-left: 10px;
        margin-bottom: 0;

        li {
            width: 98px;
            margin-left: 10px;
            padding: 0;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .shipmentWeight .list-inline {
        margin-left: 0;
        padding-left: 0;
    }
}
